<template>
    <div class="white--text">
    <v-row>
    <v-col cols="12">
    <v-breadcrumbs :items="($vuetify.lang.current === 'he') ? breadcrumbs : breadcrumbs_en">
     <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :disabled="item.disabled"
        :class="(!item.disabled) ? 'breadcrumbs' : ''"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    </v-breadcrumbs>
    <span class="white--text">{{ title }}</span>
    </v-col>
    <v-col
      cols="6"
      sm="3"
       v-for="(image, imageIndex) in items.data"
       :key="imageIndex"
       @click="addView(image.link, imageIndex, image.hits)"
       class="d-flex child-flex"
       style="cursor: pointer;"
    >
    <v-img
        :src="image.thumb"
        :lazy-src="image.thumb"
        aspect-ratio="1"
      >
     <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
    </v-row>
    <v-row>
    <v-col cols="12" v-if="ShowMore">
    <v-btn block dark @click="GetDashboard()" v-if="$vuetify.lang.current === 'he'">טען עוד...</v-btn>
    <v-btn block dark @click="GetDashboard()" v-else>Load More...</v-btn>
    </v-col>
    </v-row>
    <div dir="ltr">
    <CoolLightBox
        v-if="ShowLightBox"
        :items="items.data"
        :index="index"
        @close="index = null"
        />
    </div>
        <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    </div>
</template>

<script>
import { bus } from './../main'

export default {
  props: ['id'],
  data () {
    return {
      ShowMore: false,
      ShowLightBox: false,
      index: null,
      meta: [],
      loadingList: true,
      title: 'טוען',
      icon: 'mdi-loading',
      color: 'orange',
      breadcrumbs: [
        {
          text: 'ראשי',
          disabled: false,
          to: '/'
        },
        {
          text: 'גלריות',
          disabled: false,
          to: '/albums'
        }
      ],
      breadcrumbs_en: [
        {
          text: 'Home',
          disabled: false,
          to: '/'
        },
        {
          text: 'Galleries',
          disabled: false,
          to: '/albums'
        }
      ],
      items: []
    }
  },
  created () {
    this.breadcrumbs.push({
      text: 'טוען',
      disabled: true,
      to: '/gallery/' + this.id
    })
    this.breadcrumbs_en.push({
      text: 'Loading',
      disabled: true,
      to: '/gallery/' + this.id
    })
    this.GetDashboard()
    bus.$on('Open_gallery', () => {
      this.meta = []
      this.GetDashboard()
    })
  },
  methods: {
    GetDashboard () {
      window.Store.commit('Loader', true)
      let vm = this
      let page = 1
      if (Number(this.meta.page)) {
        page = Number(this.meta.page) + 1
      }
      window.axios.get(window.API.gallery + '?category=' + this.id + '&page=' + page + '&lang=' + this.$vuetify.lang.current)
        .then(response => {
          if (Number(this.meta.page)) {
            for (var i = 0; i < response.data.data.length; i++) {
              this.items.data.push(response.data.data[i])
            }
          } else {
            this.items = response.data
          }
          this.ShowLightBox = true
          this.meta = response.data.meta
          this.title = response.data.meta.name
          this.breadcrumbs[2].text = response.data.meta.name
          this.breadcrumbs_en[2].text = response.data.meta.name
          if (Number(this.meta.page) < Number(this.meta.totalPages)) {
            this.ShowMore = true
          } else {
            this.ShowMore = false
          }
          window.Store.commit('Loader', false)
        })
        .catch(error => {
          vm.loadingSkeleton = false
          window.Store.commit('Loader', false)
          let errorMessage = error.response.data
          bus.$emit('Open_snackbar', 'error', errorMessage.err)
        })
    },
    addView (Numid, imageIndex, hits) {
      let vm = this
      window.Store.commit('Loader', false)
      this.index = imageIndex
      window.axios.get(window.API.addview + '?num=' + Numid)
        .then(response => {
          console.log(response.data)
          if (vm.$vuetify.lang.current === 'he') {
            vm.items.data[imageIndex].description = 'מספר צפיות ' + (Number(hits) + 1)
          } else {
            vm.items.data[imageIndex].description = 'Number of views ' + (Number(hits) + 1)
          }
          vm.items.data[imageIndex].hits = (Number(hits) + 1)
        })
        .catch(error => {
          window.Store.commit('Loader', false)
          let errorMessage = error.response.data
          bus.$emit('Open_snackbar', 'error', errorMessage.err)
        })
    }
  }
}
</script>

<style>
.image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.breadcrumbs a {
    color: #ff5722!important;
}
</style>
